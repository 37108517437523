const services = [
    {
        title:"Business Audit",
        icon:'https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/Market-Research.svg',
        image:'https://img.freepik.com/free-photo/corporate-team-workers-it-company_1303-19455.jpg?t=st=1718044288~exp=1718047888~hmac=fb64784f7b63bc884432372147e3c11d3ff5e218dcb7162b6945e0cb6830b446&w=740',
        desc:'Investment Planning Working with thousands of business companies around'
    },
    {
        title:"Saving Money",
        icon:'https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/Saving-Money.svg',
        image:'https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/blog4-570x447.jpg',
        desc:'Investment Planning Working with thousands of business companies around'
    },
    {
        title:"HR Consulting",
        icon:'https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/Corporate-Finance-1.svg',
        image:'https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/gallery-widget6-570x447.jpg',
        desc:'Investment Planning Working with thousands of business companies around'
    }
]

export default services;