import React from 'react'
import './style.css'

function Footer() {
  return (
    <footer>
         
    </footer>
  )
}

export default Footer